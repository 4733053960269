.label {
  position: relative;
}

.label:after {
  content: '*';
  color: red;
  position: absolute;
  margin-left: 5px;
}

.radioItem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
}

.radioItem .input {
  display: none;
}

.radioItem .labelItem {
  font-weight: normal;
}

.radioItem .labelItem:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #efa005;
  background-color: transparent;
}

.radioItem .input:checked + .labelItem:after {
  content: ' ';
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  display: block;
  background: #efa005;
}

/* start toggle checkbox buttons */
.switchOne {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.25rem;
  background: #f0edff;
  border-radius: 2rem;
  transition: all 0.2s linear;
}

.switchOne::after {
  content: '';
  position: absolute;
  top: 0.12rem;
  left: 0.06rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #a592ff;
  transition: all 0.2s linear;
}

.inputOne:checked + .switchOne::after {
  translate: 1.3rem 0;
}
.inputOne:checked + .switchOne {
  background-color: #c3b6ff;
}

.inputOne {
  display: none;
}

.switchTwo {
  position: relative;
  display: inline-block;
  width: 2.5rem;
  height: 1.25rem;
  background: #f0edff;
  border-radius: 2rem;
  transition: all 0.2s linear;
}

.switchTwo::after {
  content: '';
  position: absolute;
  top: 0.12rem;
  left: 0.15rem;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #31005c;
  transition: all 0.2s linear;
}

.inputTwo:checked + .switchTwo::after {
  translate: 1.3rem 0;
}
.inputTwo:checked + .switchTwo {
  background-color: #c3b6ff;
}

.inputTwo {
  display: none;
}
